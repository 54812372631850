import './App.css';

function App() {
  const progress = 80; // Change this value to update the progress percentage

  return (
    <div className="container">
      <div className="content">
        <h1>Website in Build</h1>
        <p>I am currently overengineering my website</p>
        <p>It will be here soon</p>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
